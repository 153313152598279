import {
    Box,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    SelectProps,
    Typography,
    makeStyles
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import SelectIcon from "../../../../assets/select-icon.svg";
import { useViewport } from "../../../hooks/responsive";
import ButtonMain from "../ButtonMain/ButtonMain";
import { useSelectWithIconStyles } from "./SelectWithIcon2";

interface Props extends SelectProps {
    optionTitle?: string;
    options?: { label: string; value: string }[];
    label?: string;
    width?: string;
    onFormClick?: () => void;
    truncateLabel?: boolean;
    errorSelect?: string;
    applyOptions: (options: string[]) => void;
    isDurationVisible?: boolean;
}

const useStyles = makeStyles((theme) => ({
    iconButton: {
        borderRadius: "4px",
        backgroundColor: "#EBECF0",
        "&.Mui-disabled": {
            opacity: 0.3,
            border: "1px solid",
        },
        height: "44px",
        width: "44px",
    },
    mobileHover: {
        "&:hover": {
            backgroundColor: "#EBECF0 !important",
        },
    },
    menuCalc: {
        display: "flex",
        alignItems: "center",
        gap: "8px",
    },
    menu: {
        backgroundColor: "#FFFFFF",
        borderRadius: "6px",
        boxShadow:
            "0px 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 20px 24px -4px rgba(0, 0, 0, 0.04), 0px 8px 8px -4px rgba(0, 0, 0, 0.03)",
        padding: 0,
        paddingTop: "12px",
    },
    applyButton: {
        height: "40px",
    },
    menuItem: {
        "&.Mui-selected": {
            backgroundColor: "transparent !important",
        },
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
    menuItemMobile: {
        width: "inherit",
    },
    title: {
        maxWidth: "200px",
    },
    label: {
        marginLeft: "24px",
    },
    tabletLabel: {
        marginTop: "-7px",
    },
    menuItemSelect: {
        padding: "20px"
    }
}));

const MultiSelect = ({
    options,
    optionTitle,
    label,
    width,
    onFormClick,
    truncateLabel,
    errorSelect,
    applyOptions,
    isDurationVisible,
    ...props
}: Props) => {
    const selectClasses = useSelectWithIconStyles();
    const classes = useStyles();
    const { isMobile, isTabletScreen } = useViewport();
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);

    const selectRef = useRef<HTMLSelectElement | null>(null);
    const [open, setOpen] = useState(false);

    const handleFormClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (onFormClick) {
            onFormClick();
            event.stopPropagation();
        } else {
            if (selectRef.current) {
                setOpen(!open);
            }
        }
    };

    const handleClose = () => {
        if (!error) {
            setOpen(false);
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const formControlRef = useRef<HTMLDivElement>(null);
    const [widthOfFormControl, setWidthOfFormControl] = useState(0);

    useEffect(() => {
        if (formControlRef.current) {
            const rect = formControlRef.current.getBoundingClientRect();
            setWidthOfFormControl(rect.width);
        }
    }, [formControlRef.current]);

    const handleApply = (event: React.MouseEvent) => {
        event.stopPropagation();
        applyOptions(selectedOptions);
        handleClose();
    };

    const handleCheckboxChange = (optionValue: string) => {
        setSelectedOptions((prevSelectedOptions) =>
            prevSelectedOptions.includes(optionValue)
                ? prevSelectedOptions.filter((item) => item !== optionValue)
                : [...prevSelectedOptions, optionValue]
        );
    };

    return (
        <>
            <FormControl
                ref={formControlRef}
                className={`${selectClasses.form} ${selectClasses.formHover} v2`}
                style={{
                    padding: "11px 24px",
                    width,
                    cursor: "pointer",
                    maxHeight: isTabletScreen ? "48px" : "70px",
                    border: errorSelect ? "1px solid #EE4C1A" : "1px solid #F6F7FA",
                }}
                onClick={handleFormClick}
                focused={false}
            >
                {label && (
                    <InputLabel
                        className={`${selectClasses.inputLabel} ${truncateLabel ? selectClasses.truncateLabel : ""
                            } ${errorSelect ? "error-label" : ""} ${classes.label} ${isTabletScreen ? classes.tabletLabel : ""
                            }`}
                        classes={{
                            focused: isTabletScreen
                                ? selectClasses.focusedTablet
                                : selectClasses.focused,
                            shrink: isTabletScreen
                                ? selectClasses.focusedTablet
                                : selectClasses.focused,
                        }}
                        variant="standard"
                        htmlFor="uncontrolled-native"
                        shrink={selectedOptions.length > 0}
                    >
                        {label}
                    </InputLabel>
                )}
                <Select
                    native={onFormClick ? true : false}
                    disableUnderline
                    inputRef={selectRef}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onBlur={handleClose}
                    onSelect={handleClose}
                    inputProps={{
                        style: {
                            marginLeft: 0,
                            backgroundColor: "transparent",
                            marginTop: 0,
                            paddingBottom: "2px",
                            fontSize: "16px",
                            fontWeight: 500,
                            color: "#212B36",
                        },
                        onBlur: handleClose,
                        onChange: handleClose,
                        onClick: handleClose,
                    }}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => { e.preventDefault(); e.stopPropagation() }}
                    className={selectClasses.select}
                    value={selectedOptions.length > 0 ? selectedOptions.join(", ") : null}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                        },
                        getContentAnchorEl: null,
                        classes: {
                            paper: `${selectClasses.menu
                                } ${classes.menu}  ${isMobile && selectClasses.menuMobile
                                }`,
                            list: selectClasses.menuList,
                        },
                        PaperProps: {
                            style: {
                                minWidth: `${widthOfFormControl}px`,
                            },
                        },
                    }}
                    renderValue={() =>
                        selectedOptions.length > 0 ? selectedOptions.join(", ") : null
                    }
                    IconComponent={() => (
                        <img
                            src={SelectIcon}
                            alt="SelectIcon"
                            style={
                                isTabletScreen
                                    ? { marginBottom: "16px" }
                                    : { marginBottom: "12px" }
                            }
                        />
                    )}
                    {...props}
                >
                    {options?.map((option, index) => (
                        <li
                            key={index}
                            value={option.value}
                            className={`${selectClasses.menuItemAge} ${classes.menuItem} ${isMobile ? classes.menuItemMobile : ""
                                } ${isDurationVisible ? selectClasses.menuWithDuration : ""
                                } ${classes.menuItemSelect}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <Box
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                width={"100%"}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Typography
                                    className={`root-large truncate-2-lines ${classes.title}`}
                                >
                                    {option.label}
                                </Typography>
                                <Checkbox
                                    checked={selectedOptions.includes(option.value)}
                                    onChange={() => handleCheckboxChange(option.value)}
                                    color="default"
                                    inputProps={{ "aria-label": "primary checkbox" }}
                                />
                            </Box>
                        </li>
                    ))}
                    <Box px={"20px"} mb={"33px"} onClick={(e) => e.stopPropagation()}>
                        <ButtonMain
                            fullWidth
                            onClick={handleApply}
                            buttonClassName={classes.applyButton}
                        >
                            Apply
                        </ButtonMain>
                    </Box>
                </Select>
            </FormControl>
        </>
    );
};

export default MultiSelect;
