import { initializeIcons } from "@uifabric/icons";
import { ConnectedRouter } from "connected-react-router";
import mixpanel from 'mixpanel-browser';
import { loadTheme, mergeStyleSets } from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { ConnectedProps, Provider, connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { getUIConfigAction } from "./actions/ui-actions";
import Account from "./components/Account/Account";
import Account2 from "./components/Account/Account2";
import AccountCancellation from "./components/AccountCancellation/AccountCancellation";
import AuthManager from "./components/AuthManager";
import ConfirmAleternativePaymentReservation from "./components/ConfirmAleternativePaymentReservation/ConfirmAleternativePaymentReservation";
import CustomTab2 from "./components/CustomTab/CustomTab2";
import Home from "./components/Home/Home";
import Home2 from "./components/Home/Home2";
import MakePackageReservation from "./components/MakePackageReservation/MakePackageReservation";
import MakePackageReservation2 from "./components/MakePackageReservation/MakePackageReservation2";
import MakePartyConfirmation from "./components/MakePartyConfirmation/MakePartyConfirmation";
import MakePartyConfirmation2 from "./components/MakePartyConfirmation/MakePartyConfirmation2";
import MakePartyReservation from "./components/MakePartyReservation/MakePartyReservation";
import MakePartyReservation2 from "./components/MakePartyReservation/MakePartyReservation2";
import MakeReservation from "./components/MakeReservation/MakeReservation";
import Memberships from "./components/Memberships/Memberships";
import RepayReservation from "./components/RepayReservation/RepayReservation";
import RepayReservation2 from "./components/RepayReservation/RepayReservation2";
import ReservationInfo from "./components/ReservationInfo/ReservationInfo";
import ReservationInfo2 from "./components/ReservationInfo/ReservationInfo2";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import DemoLink from "./components/common/DemoLink";
import LabelLink from "./components/common/LabelLink";
import { ViewportProvider, useViewport } from "./hooks/responsive";
import { selectBootstrapped, selectUIConfig } from "./reducers/ui-reducer";
import { selectVenue } from "./reducers/venues";
import createStore from "./store";
import history from "./store/history";
import { State } from "./store/types";

loadTheme({
  defaultFontStyle: { fontFamily: "Roboto, sans-serif", fontWeight: "normal" },
  fonts: {
    small: {
      fontSize: "11px",
    },
    medium: {
      fontSize: "13px",
    },
    large: {
      fontSize: "20px",
      fontWeight: "normal",
    },
    xLarge: {
      fontSize: "22px",
      fontWeight: "normal",
    },
  },
});

// @ts-ignore
const mixpanelToken = window.mixpanelToken || "";

const mapDispatchToProps = {
  getUIConfig: getUIConfigAction,
};
const mapStateToProps = (state: State) => ({
  isBootstrapped: selectBootstrapped(state),
  uiConfig: selectUIConfig(state),
  activeVenue: selectVenue(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
const App: React.FunctionComponent<Props> = ({
  getUIConfig,
  isBootstrapped,
  uiConfig,
  activeVenue,
}) => {
  const { isMobile } = useViewport();
  const isAuthenticated = !!localStorage.getItem('auth');
  useEffect(() => {
    getUIConfig();
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken);
    }
  }, []);
  if (!isBootstrapped) {
    return <div>loading...</div>;
  }
  const theme = mergeStyleSets({
    backgroundColor: {
      background: (isMobile && uiConfig?.newDesign) ? 'white' : uiConfig?.bodyBackgroundColor,
    },
  });
  const showDemo = uiConfig?.isDemo && !uiConfig.isHideDemoMessage
  const showSandBoxPayment = !showDemo && (activeVenue?.sandBoxPayment) && !window.location.pathname.includes('select-location')
  return (
    <div className={`root ${theme.backgroundColor}`}>
      <AuthManager />
      {(showDemo || showSandBoxPayment) && <DemoLink link={uiConfig?.upgradePlanUrl || ''} isSandBoxPayment={showSandBoxPayment} />}
      {!isMobile && <LabelLink />}
      <Switch>
        <Route exact path="/select-location" component={uiConfig?.newDesign ? Home2 : Home} />
        <Route path="/reservation" component={MakeReservation} />
        <Route path="/reservation-info" component={uiConfig?.newDesign ? ReservationInfo2 : ReservationInfo} />
        <Route path="/reset-password" component={ResetPassword} />
        {uiConfig?.newDesign && <Route path="/custom-tab" component={CustomTab2} />}
        <Route path="/account" component={uiConfig?.newDesign ? Account2 : Account} />
        <Route path={`/party`} component={uiConfig?.newDesign ? MakePartyReservation2 : MakePartyReservation} />
        <Route path="/thank-you" component={uiConfig?.newDesign ? MakePartyConfirmation2 : MakePartyConfirmation} />
        <Route path="/account-cancellation" component={AccountCancellation} />
        <Route path="/pay-reservation" component={uiConfig?.newDesign ? RepayReservation2 : RepayReservation} />
        <Route path="/confirm-alternative-reservation" component={ConfirmAleternativePaymentReservation} />
        <Route path="/package" component={uiConfig?.newDesign ? MakePackageReservation2 : MakePackageReservation} />
        <Route path="/memberships" component={Memberships} authenticated={isAuthenticated} />
        <Redirect to="/select-location" />
      </Switch>
    </div>
  );
};

const AppContainer = () => {
  const { store } = createStore();
  return (
    <Provider store={store}>
      <ViewportProvider>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <Route path="/" component={connector(App)} />
          </LastLocationProvider>
        </ConnectedRouter>
      </ViewportProvider>
    </Provider>
  );
};

initializeIcons();

export default AppContainer;
