import { PMethod } from './venues';
import {
  GiftCardBalanceDto as GiftCardBalance,
  GiftCardPaymentRequestDto as GiftCardPaymentRequest,
} from "../../../../server/src/dto/giftCard.dto";
import { ReservationDto as Reservation, } from '../../../../server/src/dto/reservation.dto';
import { FeeType } from '../../../../server/src/entities/enums';
import { ReservationAddonDto as ReservationAddon } from '../../../../server/src/dto/reservationAddon.dto';
import { StripeSavedCard } from '../../components/common/StripeForm/utils';

export { CurrencyType, ReservationStatus } from "../../../../server/src/entities/enums";
export {
  GiftCardBalanceRequestDto as GiftCardBalanceRequest,
  GiftCardBalanceDto as GiftCardBalance,
  GiftCardPaymentRequestDto as GiftCardPaymentRequest,
} from "../../../../server/src/dto/giftCard.dto";
export { ReservationDto as Reservation, CreateSquarePartialPaymentDto } from '../../../../server/src/dto/reservation.dto';
export { PaymentType, FeeType, PizzaType } from '../../../../server/src/entities/enums';
export { ReservationAddonDto as ReservationAddon } from '../../../../server/src/dto/reservationAddon.dto';
export { PlayerDetailsForSteltronicInfo } from '../../../../server/src/services/types/steltronic';
export { SquareOrderDtoRes } from '../../../../server/src/dto/square.dto';

export interface ReservationCustomFields {
  [key: string]: string | null;
}

export interface ReservationConfirmation {
  firstName?: string;
  lastName?: string;
  phone?: string;
  occasion?: string;
  guestDetail?: string;
  customNotes?: string;
  email?: string;
  customFieldsInformation?: string;
}

export interface PartyRequest {
  venueId: string;
  date: string;
  startTime: string;
  endTime: string;
  timeSlot: number;
  duration: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  occasion?: string;
  guestCount: string;
  subscribe: boolean;
  device: string;
  GA_Medium__c?: string;
  GA_Source__c?: string;
  GA_Campaign__c?: string;
  GA_Term__c?: string;
  GA_AdGroup__c?: string;
  GCLIDGCLID__c?: string;
  zuevsoftware__gaconnector_Google_Analytics_Client_ID__c?: string;
  gaconnector_Google_Analytics_Client_ID__c?: string;
  lead_source?: string;
}

interface PaymentDetails {
  cardNumber: string;
  cardBin: string;
  cardLast4: string;
  cardType: string;
  expiryMonth: string;
  expiryYear: string;
  cardholderName: string;
}
interface StripePaymentDetails {
  cardLast4: string;
  cardType: string;
  paymentMethodId: string;
}
interface SezzlePaymentDetails {
  amount: number;
  orderId: string;
}
interface FortisPaymentDetails {
  created_ts: number;
  created_user_id: string;
  exp_date: string;
  expires_ts: number;
  first_six: string;
  last_four: string;
  location_id: string;
}

export interface SquarePaymentDetails {
  sourceId: string;
  locationId: string;
  idempotencyKey: string;
  verificationToken: string;
  delayDuration?: string;
  delayAction?: string;
  autocomplete?: boolean;
  orderId?: string;
  customerId?: string;
  teamMemberId?: string;
  referenceId?: string;
  acceptPartialAuthorization?: boolean;
  buyerEmailAddress?: string;
  note?: string;
  statementDescriptionIdentifier?: string;
}
export interface Payment {
  paymentReference: string;
  details: PaymentDetails | StripePaymentDetails | SezzlePaymentDetails | FortisPaymentDetails | SquarePaymentDetails;
  postalCode: string;
  paymentType: PMethod;
}

export interface ReservationState {
  confirmed: boolean;
  isPaymentInProgress: boolean;
  isRequestInProgress: boolean;
  isCardBalanceInProgress: boolean;
  isStripeSavedCardsRequestInProgress: boolean;
  paymentCompleted: boolean;
  reservation: Reservation;
  confirmation: ReservationConfirmation;
  reservations: Reservation[];
  error?: string;
  isPartySubmitted: boolean;
  party?: PartyRequest;
  cancellationFee?: string;
  cancellationFeeType?: FeeType;
  showModification: boolean;
  feeForModification?: number;
  modificationFeeType?: FeeType;
  showCancellation: boolean;
  giftCardBalance?: GiftCardBalance;
  giftBalanceError?: string;
  giftCardPayment: GiftCardPaymentRequest[];
  giftCardAmount: number;
  isUpdateReservation: boolean;
  isUpdateWithVenueChange: boolean;
  oldReservation?: Reservation;
  reservationAddons: ReservationAddon[];
  fortisClientToken?: string;
  goTabReservationId?: string;
  listOfStripeSavedCards: StripeSavedCard[];
}
