import { getHash, getSearch, replace } from "connected-react-router";
import dayjs from "dayjs";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { ActionTypeEnum, AppThunk } from ".";
import { DATE_FORMAT } from "../../../common/constants/timedate";
import {
  formatLanesCount,
  getPackageAssignedDuration,
  getPackageDurations,
  getVenueAssignedDuration,
  getVenueDurations,
} from "../../../common/utils/formats";
import { AddonModifierDto } from "../../../server/src/dto/addonModifier.dto";
import { selectGuest } from "../reducers/auth-reducer";
import {
  selectIsUpdateReservation,
  selectReservation,
  selectReservationAddons,
} from "../reducers/reservation";
import { selectUIConfig } from "../reducers/ui-reducer";
import {
  selectAddonsQuantities,
  selectCurrentPackage,
  selectVenue,
} from "../reducers/venues";
import {
  Addon,
  CurrencyType,
  PackageName,
  Reservation,
  ReservationAddon,
  State,
  UrlSearchParams,
  Venue,
} from "../store/types";
import { parseUrlHash, parseUrlQuery, toUrlHash } from "../utils/urlSearchQuery";
import { SQUARE_URL, get, getWithAuth, getWithGuestAuth, post, postWithAuth } from "./api";
import {
  clearReservationAddons,
  pushUrlPathAction,
  updateReservationAction,
  updateReservationUrlAction,
} from "./reservation-actions";
import { GuestsWithAgeGroup } from "../../../server/src/dto/guestsWithAgeGroup";

const GET_VENUES_URL = "/api/venue";

const FEATURE_HASH = "#featured";

const getVenueAvailabilityAndDispatchResult = async (
  venueId: string,
  dispatch: ThunkDispatch<State, unknown, Action<string>>,
  forPackage: boolean = false
) => {
  const response = await get(
    `${GET_VENUES_URL}/${venueId}?for-package=${forPackage}`
  );
  dispatch({
    type: ActionTypeEnum.GetVenueWithAvailabilitySuccess,
    payload: response.data,
  });
  return response.data;
};

const getVenueWithPackage = async ({
  venueId,
  date,
  packageId,
  guests,
  packagesTag,
  duration,
  skipReservationId,
  targetPackage,
  guestsWithAgeGroups,
}: {
  venueId: string;
  date: string;
  packageId?: string;
  guests: number;
  packagesTag?: string;
  duration: number;
  skipReservationId?: string;
  targetPackage?: string;
  guestsWithAgeGroups?: GuestsWithAgeGroup[],
}) => {
  if (targetPackage && !packageId) {
    // select target package by default
    packageId = targetPackage;
  }
  const response = await postWithAuth(`${GET_VENUES_URL}/get-venue-with-package`, {
    venueId,
    date,
    packageId,
    guests,
    packagesTag,
    duration,
    skipReservationId,
    targetPackage,
    guestsWithAgeGroups,
  });
  const selectedVenue = response.data;
  let packageToSelect;
  if (targetPackage) {
    packageToSelect = selectedVenue.packages.find(
      (item: PackageName) => item.id === targetPackage
    );
  } else {
    packageToSelect = selectedVenue.packages.find(
      (item: PackageName) => item.id === packageId
    );
  }
  return { selectedVenue, packageToSelect };
};

export const parseHash = (
  hash?: string
): { singlePackage: boolean; packagesTag: string } => {
  if (hash === FEATURE_HASH) {
    return { singlePackage: true, packagesTag: "" };
  }
  return {
    singlePackage: false,
    packagesTag: parseUrlHash(hash)?.replace("#", ""),
  };
};

export const initialLoadVenuesAction =
  ({
    venue,
    date,
    guests,
    isPackages,
  }: {
    venue?: string;
    date?: string;
    guests: number;
    isPackages: boolean;
  }): AppThunk =>
    async (dispatch, getState) => {
      try {
        const isPaymentLink = window.location.pathname.includes("pay-reservation")
        if (isPaymentLink) {
          // no need to load venues if we are on payment page
          return;
        }
        dispatch({ type: ActionTypeEnum.StopBooking });
        const searchParams = getSearch(getState());
        const { currentPackage, sp: targetPackage } = parseUrlQuery(searchParams);
        let { venues } = getState().venues;
        let { packagesTag } = parseHash(getHash(getState()));
        const uiConfig = selectUIConfig(getState());
        if (!venues || venues.length === 0) {
          dispatch({ type: ActionTypeEnum.GetVenues });
          const response = await get(GET_VENUES_URL);
          venues = response.data;
        }
        const urlVenue = venues?.find((v: any) => venue && v.name === venue);
        const openedVenues =
          venues && venues.length > 0
            ? venues.filter((v) => !v.isComingSoon)
            : [];
        const selectedVenueId = urlVenue?.id ? urlVenue.id : openedVenues[0].id;
        dispatch(getVenueAction(selectedVenueId));
        //actually we need to load availability here, todo refactor it in future
        let response = await getVenueAvailabilityAndDispatchResult(
          selectedVenueId,
          dispatch,
          isPackages
        );

        const d = dayjs(date).isValid()
          ? dayjs(date).format(DATE_FORMAT)
          : dayjs().format(DATE_FORMAT);
        const avVenue = response.venue as Venue;
        const isOnlyOneLane =
          uiConfig?.hideNumberOfLines && !uiConfig?.subtractMultiLanes;
        const lanes = formatLanesCount(guests, avVenue.guestSplit, isOnlyOneLane);
        const parsedDuration =
          uiConfig && uiConfig.hideDuration
            ? uiConfig.defaultDuration
            : avVenue.enableAssignDuration
              ? getVenueAssignedDuration(avVenue, guests)
              : getVenueDurations(avVenue)[0];
        response = await post(`${GET_VENUES_URL}/get`, {
          venueId: selectedVenueId,
          date: d,
          duration: parsedDuration,
          lanes,
          guests,
          packagesTag: currentPackage ? "" : packagesTag,
          targetPackage,
        });
        let selectedVenue = response.data as Venue;

        // recalculate packagesTag
        if (
          !packagesTag &&
          selectedVenue.packageTags?.length > 0 &&
          uiConfig?.hideAllPackageTab && !currentPackage
        ) {
          packagesTag = selectedVenue.packageTags[0];
          const newHash = toUrlHash(packagesTag);
          dispatch(replace({ search: searchParams, hash: newHash }));
        }

        //set current package
        let packageToSelect;
        let newLanes = lanes;
        let newDuration = parsedDuration;
        let newGuests = guests;
        let depositParam = +selectedVenue.deposit;
        let depositType = selectedVenue.depositType;

        if (isPackages) {
          if (currentPackage) {
            packageToSelect = selectedVenue.packages.find(
              (p: PackageName) => p.name === currentPackage
            );
          }

          if (targetPackage) {
            packageToSelect = selectedVenue.packages.find(
              (p: PackageName) => p.id === targetPackage
            );
          }

          if (!!packageToSelect) {
            newLanes = packageToSelect.numberOfLanes;
            if (!packageToSelect.enableDurationChoice) {
              newDuration = packageToSelect.duration;
            } else {
              newDuration = getPackageDurations(packageToSelect)[0];
            }
            if (packageToSelect.enableAssignDuration) {
              newDuration = getPackageAssignedDuration(packageToSelect, guests);
            }
            if (
              packageToSelect.countLanesByGuest &&
              packageToSelect.maxGuestsPerLane
            ) {
              newLanes = Math.ceil(guests / packageToSelect.maxGuestsPerLane);
            }

            const result = await getVenueWithPackage({
              venueId: selectedVenueId,
              date: d,
              packageId: packageToSelect.id,
              guests: newGuests,
              packagesTag: currentPackage ? "" : packagesTag,
              duration: newDuration,
              targetPackage,
            });
            selectedVenue = result.selectedVenue;
            packageToSelect = result.packageToSelect;

            if (targetPackage) {
              packageToSelect = selectedVenue.packages.find(
                (item: PackageName) => item.id === targetPackage
              );
            } else {
              packageToSelect = selectedVenue.packages.find(
                (item: PackageName) => item.name === currentPackage
              );
            }
          } else {
            const result = await getVenueWithPackage({
              venueId: selectedVenueId,
              date: d,
              packageId: undefined,
              guests: newGuests,
              packagesTag: currentPackage ? "" : packagesTag,
              duration: newDuration,
              targetPackage,
            });
            selectedVenue = result.selectedVenue;

            console.log(
              "bad condition, cannot find package - ",
              currentPackage,
              "will use the first one"
            );
            //restore params
            packageToSelect = undefined;
          }
        }

        dispatch({
          type: ActionTypeEnum.GetVenuesSuccess,
          payload: {
            venues,
            venue: selectedVenue,
          },
        });
        //avoid change reservation if app in repay state
        if (
          window.location.pathname.includes("pay-reservation") ||
          window.location.pathname.includes("account") ||
          window.location.pathname.includes("reset-password") ||
          window.location.pathname.includes("memberships")
        ) {
          return;
        }
        if (packageToSelect) {
          depositParam = +packageToSelect.deposit;
          depositType = packageToSelect.depositType;
        }
        dispatch(
          updateReservationAction({
            venueId: selectedVenueId,
            textBox: selectedVenue.textBox,
            depositParam,
            depositType,
            date: d,
            slots: undefined,
            duration: newDuration,
            guests: newGuests,
            lanes: newLanes,
            packageId: packageToSelect?.id,
          })
        );
        dispatch(setCurrentPackageAction(packageToSelect));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenuesFailure,
          payload: "error getting venues",
        });
      }
    };

export const changeVenueAction =
  ({
    venueId,
    date,
    duration,
    lanes,
    slots,
    guests,
    updatePackage,
  }: {
    venueId: string;
    date: string;
    duration: number;
    lanes: number;
    slots?: number;
    guests: number;
    updatePackage?: PackageName;
  }): AppThunk =>
    async (dispatch, getState) => {
      try {
        const venue = selectVenue(getState());
        let { packagesTag } = parseHash(getHash(getState()));
        const currentPackage = selectCurrentPackage(getState());
        dispatch({ type: ActionTypeEnum.GetVenue });

        let packageToSelect = currentPackage;
        if (venue?.id !== venueId) {
          // process new venue
          //actually we need to load availability here, todo refactor it in future
          await getVenueAvailabilityAndDispatchResult(
            venueId,
            dispatch,
            !!updatePackage
          );
          packageToSelect = undefined;
        }

        let response = await post(`${GET_VENUES_URL}/get`, {
          venueId,
          date,
          duration,
          lanes,
          guests,
          packagesTag,
        });
        let newVenue = response.data;

        dispatch({
          type: ActionTypeEnum.GetVenueSuccess,
          payload: { venue: newVenue },
        });
        dispatch(clearReservationAddons());
        dispatch(
          updateReservationAction({
            venueId: newVenue.id,
            textBox: newVenue.textBox,
            depositParam: +newVenue.deposit,
            depositType: newVenue.depositType,
            date,
            duration,
            lanes,
            guests,
            slots: undefined, //response.data.timeSlots[0]?.time
            preBufferTime: newVenue.preBufferTimeForReservation,
            bufferTime: newVenue.bufferTimeForReservation,
            packageId: packageToSelect?.id,
            allowCancellation: newVenue.allowCancellation,
          })
        );
        dispatch(setCurrentPackageAction(packageToSelect));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenueFailure,
          payload: "error getting venue",
        });
      }
    };
export const changeVenuePackageAction =
  ({
    updatePackage,
    guests,
    date,
    venueId,
    selectDuration,
    refreshAvailability = false,
    changePackageList,
    changeDateOrVenue,
    changeVenueWithoutPackage,
    guestsWithAgeGroups,
  }: {
    guests: number;
    date: string;
    venueId: string;
    updatePackage?: PackageName;
    selectDuration?: number;
    refreshAvailability?: boolean;
    changePackageList?: boolean;
    changeDateOrVenue?: boolean;
    changeVenueWithoutPackage?: boolean;
    guestsWithAgeGroups?: GuestsWithAgeGroup[],
  }): AppThunk =>
    async (dispatch, getState) => {
      try {
        date = date || dayjs().format(DATE_FORMAT);
        const searchParams = getSearch(getState());
        let { sp: targetPackage } = parseUrlQuery(searchParams);
        const venue = selectVenue(getState());
        let { packagesTag } = parseHash(getHash(getState()));
        const reservation = selectReservation(getState());
        const currentPackage = selectCurrentPackage(getState());
        dispatch({
          type: ActionTypeEnum.GetVenue, payload: {
            changePackageList,
            changeDateOrVenue
          }
        });
        let { lanes, duration } = reservation;
        if (selectDuration) {
          duration = selectDuration;
        }
        let newVenue = venue as Venue;
        let packageToSelect = currentPackage;


        if (venue?.id !== venueId) {
          targetPackage = "";
        }
        if (venue?.id !== venueId || refreshAvailability) {
          await getVenueAvailabilityAndDispatchResult(venueId, dispatch, true);
          let response = await post(`${GET_VENUES_URL}/get`, {
            venueId,
            date,
            duration,
            lanes,
            guests,
            packagesTag,
          });
          newVenue = response.data;
        }
        if (!newVenue) {
          throw "cannot get venue info";
        }
        let packageId = packageToSelect?.id;
        if (!!updatePackage) {
          packageId = updatePackage.id;
          if (!updatePackage.enableDurationChoice) {
            duration = updatePackage.duration;
          }
          if (updatePackage.enableDurationChoice && !selectDuration) {
            duration = getPackageDurations(updatePackage)[0];
          }
          if (updatePackage.enableAssignDuration) {
            duration = getPackageAssignedDuration(updatePackage, guests);
          }

          lanes = updatePackage.numberOfLanes;
          if (updatePackage.countLanesByGuest && updatePackage.maxGuestsPerLane) {
            lanes = Math.ceil(guests / updatePackage.maxGuestsPerLane);
          }
        }
        let result;

        if (changeVenueWithoutPackage) {
          result = await getVenueWithPackage({
            venueId,
            date,
            guests,
            packagesTag: '',
            duration,
            guestsWithAgeGroups,
          });
        } else {
          result = await getVenueWithPackage({
            venueId,
            date,
            packageId,
            guests,
            packagesTag,
            duration,
            targetPackage,
            guestsWithAgeGroups,
          });
        }


        newVenue = result.selectedVenue;
        packageToSelect = result.packageToSelect;

        if (!packageToSelect) {
          console.log(
            "bad condition, cannot find package - ",
            updatePackage?.name,
            "will use the first one"
          );
          packageToSelect = undefined;
          //reset res params
        }

        dispatch({
          type: ActionTypeEnum.GetVenueSuccess,
          payload: { venue: newVenue },
        });



        let depositParam = +newVenue.deposit;
        let depositType = newVenue.depositType;
        if (packageToSelect && packageToSelect.deposit) {
          depositParam = +packageToSelect.deposit;
          depositType = packageToSelect.depositType;
        }
        dispatch(setCurrentPackageAction(packageToSelect));
        dispatch(clearReservationAddons());
        dispatch(
          updateReservationAction({
            venueId: newVenue.id,
            textBox: newVenue.textBox,
            depositParam,
            depositType,
            date,
            duration,
            lanes,
            guests,
            slots: undefined,
            preBufferTime: packageToSelect?.preBufferTimeForReservation,
            bufferTime: packageToSelect?.bufferTimeForReservation,
            packageId: packageToSelect?.id,
            allowCancellation: !!packageToSelect?.allowCancellation,
          })
        );
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenueFailure,
          payload: "error getting venue",
        });
      }
    };


export const getVenueAction =
  (venueId: string, forPackage: boolean = false): AppThunk =>
    async (dispatch) => {
      try {
        dispatch({ type: ActionTypeEnum.GetVenueWithAvailability });
        await getVenueAvailabilityAndDispatchResult(
          venueId,
          dispatch,
          forPackage
        );
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenueWithAvailabilityFailure,
          payload: "error getting venue availability",
        });
      }
    };

const getReservationSearchParams = (state: State): UrlSearchParams => {
  const reservation = selectReservation(state);
  const venue = selectVenue(state);
  const currentPackage = selectCurrentPackage(state);
  if (!venue) {
    return {};
  }
  const result: UrlSearchParams = {
    venue: venue.name,
  };
  if (reservation.date) {
    result.date = reservation.date;
  }
  if (reservation.guests) {
    result.guests = reservation.guests;
  }
  if (!!currentPackage) {
    result.selectedPackage = currentPackage?.name;
  }
  console.log("-", result);

  return result;
};

export const backToReservationAction =
  (): AppThunk => async (dispatch, getState) => {
    const params = getReservationSearchParams(getState());
    const uiConfig = selectUIConfig(getState());
    const isPackageReservationMode =
      params.selectedPackage || uiConfig?.isPackageReservationMode;
    dispatch(
      pushUrlPathAction(
        isPackageReservationMode ? "/package" : "/reservation",
        params
      )
    );
  };

export const backToPackagesAction =
  (): AppThunk => async (dispatch, getState) => {
    const params = getReservationSearchParams(getState());
    const uiConfig = selectUIConfig(getState());
    const isPackageReservationMode =
      params.selectedPackage || uiConfig?.isPackageReservationMode;
    dispatch(
      pushUrlPathAction(
        isPackageReservationMode ? "/package" : "/reservation"
      )
    );
  };
export const toReservationWithVenueAction =
  (date: string, numberOfGuests?: number): AppThunk =>
    async (dispatch, getState) => {
      const venue = selectVenue(getState());
      const reservation = selectReservation(getState());
      const isUpdateReservation = selectIsUpdateReservation(getState());
      const guests =
        venue && numberOfGuests && numberOfGuests <= venue?.maxGuests
          ? numberOfGuests
          : 1;
      dispatch(
        pushUrlPathAction("/reservation", { venue: venue?.name, date, guests })
      );
      if (venue) {
        await getVenueAvailabilityAndDispatchResult(venue.id, dispatch, false);
      }
      if (isUpdateReservation && !!reservation?.id) {
        dispatch(resetReservationWithVenue(reservation, guests, venue));
      }
      dispatch(updateReservationAction({ packageId: undefined, guests }));
      dispatch(setCurrentPackageAction());
      dispatch(clearReservationAddons());
    };

export const toPackagesWithVenueAction =
  (): AppThunk => async (dispatch, getState) => {
    const { venueId, date, guests } = selectReservation(getState());
    dispatch(
      pushUrlPathAction(
        "/package"
        // search: toUrlQuery({ venue: venue?.name, date, guests }),
      )
    );
    dispatch(
      changeVenuePackageAction({
        guests,
        date,
        venueId,
        refreshAvailability: true,
      })
    );
    dispatch(clearReservationAddons());
  };

export const toPackagesWithVenueNewDesignAction =
  (): AppThunk => async (dispatch) => {
    dispatch(
      pushUrlPathAction(
        "/package"
      )
    );

    dispatch(clearReservationAddons());
  };

export const toPartyWithVenueAction =
  (date: string): AppThunk =>
    async (dispatch, getState) => {
      const venue = selectVenue(getState());
      dispatch(clearReservationAddons());
      if (venue?.partyUrl) {
        document.location.href = venue.partyUrl;
      } else {
        dispatch(pushUrlPathAction("/party", { venue: venue?.name, date }));
      }
    };
export const toCustomTabAction =
  (): AppThunk =>
    async (dispatch, getState) => {
      const { venueId, date, guests } = selectReservation(getState());

      dispatch(clearReservationAddons());
      dispatch(pushUrlPathAction("/custom-tab"));

      dispatch(
        changeVenuePackageAction({
          guests,
          date,
          venueId,
        })
      );

    };
export const toCustomTabNewDesignAction =
  (): AppThunk =>
    async (dispatch) => {
      dispatch(clearReservationAddons());
      dispatch(pushUrlPathAction("/custom-tab"));
    };

export const setCurrentPackageAction =
  (currentPackage?: PackageName): AppThunk =>
    async (dispatch) => {
      dispatch({
        type: ActionTypeEnum.SetCurrentPackage,
        payload: currentPackage,
      });
      dispatch(updateReservationUrlAction());
    };

export const setAddonQuantityAction =
  ({ addonId, quantity }: { addonId: string; quantity: number }): AppThunk =>
    (dispatch, getState) => {
      const addonQuantities = selectAddonsQuantities(getState());

      dispatch({
        type: ActionTypeEnum.SetAddonQuantities,
        payload: [...addonQuantities, { addonId, quantity }],
      });
    };

export const toggleAddonSelectedStateAction =
  (addon: Addon, isSelected: boolean, quantity: number, modifiers?: AddonModifierDto[], notes?: string): AppThunk =>
    (dispatch, getState) => {
      const reservationAddons = selectReservationAddons(getState());
      let newReservationAddons: ReservationAddon[] = [];
      const filteredReservation = reservationAddons.filter(
        (item) => item.addonId !== addon.id
      );
      if (isSelected) {
        const newAddon: ReservationAddon = {
          addonId: addon.id,
          name: addon.name,
          method: addon.method,
          price: addon.price,
          quantity: quantity || 0,
          addonType: addon.addonType,
          depositPercentage: addon.depositPercentage,
          notes: notes || '',
          modifiers: modifiers as AddonModifierDto[]
        };
        newReservationAddons = [...filteredReservation, newAddon];
      } else {
        newReservationAddons = filteredReservation;
      }
      dispatch({
        type: ActionTypeEnum.SetReservationAddons,
        payload: newReservationAddons,
      });
    };
export const changeVenueWithReservationAction =
  ({
    venueId,
    date,
    duration,
    lanes,
    slots,
    guests,
    updatePackage,
  }: {
    venueId?: string;
    date?: string;
    duration?: number;
    lanes?: number;
    slots?: number;
    guests?: number;
    updatePackage?: PackageName;
  }): AppThunk =>
    async (dispatch, getState) => {
      try {
        const reservation = selectReservation(getState());
        const venue = selectVenue(getState());
        dispatch({ type: ActionTypeEnum.GetVenue });
        dispatch({ type: ActionTypeEnum.SetReservationError });
        if (!venueId || !venue) {
          await getVenueAvailabilityAndDispatchResult(
            reservation.venueId,
            dispatch,
            !!reservation.packageId
          );
        } else if (venue?.id !== venueId) {
          // todo: refactor it
          let response = await get(`${GET_VENUES_URL}/${venueId}`);
          if (
            (venue?.stripePublishableKey &&
              !response.data?.venue?.stripePublishableKey) ||
            (venue?.paymentKey && !response.data?.venue?.paymentKey) ||
            (venue?.fortisUrl && !response.data?.venue?.fortisUrl)
          ) {
            dispatch({
              type: ActionTypeEnum.GetVenueFailure,
              payload: "error getting venue with reservation",
            });
            dispatch({
              type: ActionTypeEnum.SetReservationError,
              payload:
                "Unable to modify your reservation at this time. Please contact the venue directly",
            });
            return;
          }
          dispatch({
            type: ActionTypeEnum.GetVenueWithAvailabilitySuccess,
            payload: response.data,
          });
        }
        if (!!venueId) {
          dispatch({ type: ActionTypeEnum.SetUpdatingWithVenueChange });
        }
        let response = await post(`${GET_VENUES_URL}/get`, {
          venueId: venueId || reservation.venueId,
          date: date || reservation.date,
          duration: duration || reservation.duration,
          lanes: lanes || reservation.lanes,
          guests: guests || reservation.guests,
          skipReservationId: reservation.id,
        });
        let newVenue = response.data;
        dispatch({
          type: ActionTypeEnum.GetVenueSuccess,
          payload: { venue: newVenue },
        });
        dispatch(clearReservationAddons());
        dispatch(
          updateReservationAction({
            venueId: newVenue.id,
            textBox: newVenue.textBox,
            depositParam: +newVenue.deposit,
            depositType: newVenue.depositType,
            date: date || reservation.date,
            duration: duration || reservation.duration,
            lanes: lanes || reservation.lanes,
            guests: guests || reservation.guests,
            slots: reservation.slots,
            preBufferTime: newVenue.preBufferTimeForReservation,
            bufferTime: newVenue.bufferTimeForReservation,
            packageId: undefined,
            allowCancellation: newVenue.allowCancellation,
          })
        );
        dispatch(setCurrentPackageAction(undefined));
      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenueFailure,
          payload: "error getting venue with reservation",
        });
      }
    };

export const changeVenuePackageWithReservationAction =
  ({
    updatePackage,
    guests,
    date,
    venueId,
    selectDuration,
    changePackageList,
    changeDateOrVenue
  }: {
    guests?: number;
    date?: string;
    venueId?: string;
    updatePackage?: PackageName;
    selectDuration?: number;
    changePackageList?: boolean;
    changeDateOrVenue?: boolean;
  }): AppThunk =>
    async (dispatch, getState) => {
      try {
        const searchParams = getSearch(getState());
        let { sp: targetPackage } = parseUrlQuery(searchParams);
        const venue = selectVenue(getState());
        let { packagesTag } = parseHash(getHash(getState()));
        const reservation = selectReservation(getState());
        dispatch({
          type: ActionTypeEnum.GetVenue, payload: {
            changePackageList,
            changeDateOrVenue
          }
        }); dispatch({ type: ActionTypeEnum.SetReservationError });
        let { lanes, duration } = reservation;
        let peopleCount = guests ? guests : reservation.guests;
        if (selectDuration) {
          duration = selectDuration;
        }
        let newVenue = venue as Venue;
        if (!venueId || !venue || venue?.id !== venueId) {
          targetPackage = "";
          let response = await post(`${GET_VENUES_URL}/get`, {
            venueId: venueId || reservation.venueId,
            date: date || reservation.date,
            duration: duration || reservation.duration,
            lanes: lanes || reservation.lanes,
            guests: guests || reservation.guests,
            skipReservationId: reservation.id,
            packagesTag,
          });
          if (
            (venue?.stripePublishableKey &&
              !response.data?.stripePublishableKey) ||
            (venue?.paymentKey && !response.data?.paymentKey) ||
            (venue?.fortisUrl && !response.data?.fortisUrl)
          ) {
            dispatch({
              type: ActionTypeEnum.GetVenueFailure,
              payload: "error getting venue with reservation",
            });
            dispatch({
              type: ActionTypeEnum.SetReservationError,
              payload:
                "Unable to modify your reservation at this time. Please contact the venue directly",
            });
            return;
          }
          newVenue = response.data;
        }
        if (!!venueId) {
          dispatch({ type: ActionTypeEnum.SetUpdatingWithVenueChange });
        }
        if (!newVenue) {
          throw "cannot get venue info";
        }
        let packageId = reservation?.packageId;
        if (!!updatePackage) {
          packageId = updatePackage.id;
          if (!updatePackage.enableDurationChoice) {
            duration = updatePackage.duration;
          }
          if (updatePackage.enableDurationChoice && !selectDuration) {
            duration = getPackageDurations(updatePackage)[0];
          }
          if (updatePackage.enableAssignDuration) {
            duration = getPackageAssignedDuration(updatePackage, peopleCount);
          }

          lanes = updatePackage.numberOfLanes;
          if (updatePackage.countLanesByGuest && updatePackage.maxGuestsPerLane) {
            lanes = Math.ceil(peopleCount / updatePackage.maxGuestsPerLane);
          }
        }
        const result = await getVenueWithPackage({
          venueId: venueId || reservation.venueId,
          date: date || reservation.date,
          packageId,
          guests: guests || reservation.guests,
          duration,
          packagesTag,
          skipReservationId: reservation.id,
          targetPackage,
        });
        newVenue = result.selectedVenue;
        let packageToSelect = result.packageToSelect;

        if (!packageToSelect) {
          console.log(
            "bad condition, cannot find package - ",
            updatePackage?.name,
            "will use the first one"
          );
          packageToSelect = undefined;
          //reset res params
        }

        dispatch({
          type: ActionTypeEnum.GetVenueSuccess,
          payload: { venue: newVenue },
        });


        let depositParam = +newVenue.deposit;
        let depositType = newVenue.depositType;
        if (packageToSelect && packageToSelect.deposit) {
          depositParam = +packageToSelect.deposit;
          depositType = packageToSelect.depositType;
        }

        dispatch(setCurrentPackageAction(packageToSelect));
        dispatch(clearReservationAddons());
        dispatch(
          updateReservationAction({
            venueId: newVenue.id,
            textBox: newVenue.textBox,
            depositParam,
            depositType,
            date: date || reservation.date,
            duration: duration || reservation.duration,
            lanes: lanes || reservation.lanes,
            guests: peopleCount,
            slots: reservation.slots,
            preBufferTime: packageToSelect?.preBufferTimeForReservation,
            bufferTime: packageToSelect?.bufferTimeForReservation,
            packageId: packageToSelect?.id,
            allowCancellation: !!packageToSelect?.allowCancellation,
          })
        );

      } catch (e) {
        dispatch({
          type: ActionTypeEnum.GetVenueFailure,
          payload: "error getting venue",
        });
      }
    };

const resetReservationWithVenue =
  (reservation: Reservation, guests: number, venue?: Venue): AppThunk =>
    async (dispatch, getState) => {
      if (!!venue) {
        const uiConfig = selectUIConfig(getState());
        const isHideDuration = uiConfig?.hideDuration;
        const defaultDuration = uiConfig?.defaultDuration;
        const isHideNumberOfLines = uiConfig?.hideNumberOfLines;
        const isSubtractMultiLanes = uiConfig?.subtractMultiLanes;
        const isOnlyOneLane = isHideNumberOfLines && !isSubtractMultiLanes;
        const parsedDate =
          reservation.date && reservation.date !== "undefined"
            ? reservation.date
            : dayjs().format(DATE_FORMAT);
        const parsedDuration =
          isHideDuration && defaultDuration
            ? defaultDuration
            : venue.enableAssignDuration
              ? getVenueAssignedDuration(venue, guests) ||
              getVenueDurations(venue)[0]
              : getVenueDurations(venue)[0];
        const parsedLanes = formatLanesCount(
          guests,
          venue.guestSplit,
          isOnlyOneLane
        );
        const timeSlotShifting =
          +venue.timeSlotShifting[dayjs(parsedDate).day()] || 0;
        dispatch(clearReservationAddons());
        dispatch(
          updateReservationAction({
            venueId: venue.id,
            date: parsedDate,
            slots: undefined,
            duration: parsedDuration,
            guests,
            lanes: reservation.venueId === venue.id ? parsedLanes : 1,
            depositParam: +venue.deposit,
            depositType: venue.depositType,
            textBox: venue.textBox,
            pricing: venue.pricing,
            timeSlotDuration: venue.timeSlotDuration,
            allowCancellation: venue.allowCancellation,
            timeZone: venue.timeZone,
            venueName: venue.name,
            venueAddress: `${venue.address} ${venue?.address2 || ""}`,
            timeSlotShifting: timeSlotShifting,
            startTime: 0,
            preBufferTime: venue.preBufferTimeForReservation,
            bufferTime: venue.bufferTimeForReservation,
            currency: uiConfig?.currency || CurrencyType.USD,
            packageId: undefined,
            packageName: undefined,
          })
        );
      }
    };

export const getSquareAppIdAction =
  (): AppThunk<Promise<string>> => async (dispatch, getState) => {
    try {
      const guest = selectGuest(getState());
      const isGuest = Boolean(guest);
      const getWith = isGuest ? getWithGuestAuth : getWithAuth;
      dispatch({ type: ActionTypeEnum.GetSquareAppId });
      const response = await getWith<string>(`${SQUARE_URL}/get-app-id`);
      dispatch({
        type: ActionTypeEnum.GetSquareAppIdSuccess,
        payload: response.data,
      });
      return response.data;
    } catch (e) {
      dispatch({
        type: ActionTypeEnum.GetSquareAppIdFailure,
        payload: "error get app id Square",
      });
      throw new Error("error get app id Square");
    }
  };
