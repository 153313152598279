import { Box, Button, Link, Menu, MenuItem, Typography } from "@material-ui/core";
import { ArrowBack, Check, ChevronLeft, Person } from "@material-ui/icons";
import { getHash, getLocation, getSearch, replace } from "connected-react-router";
import {
    DefaultButton,
    IButtonStyles,
    IIconProps,
    IconButton,
    mergeStyleSets,
    registerIcons,
} from "office-ui-fabric-react";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import {
    formatDuration,
    formatPrice,
    formatReservationDate,
    getReservationTotalPrice,
    slotToTime,
} from "../../../../../common/utils/formats";
import { DateFormat } from "../../../../../server/src/entities/enums";
import LoginIcon from "../../../../assets/login-icon.svgr";
import { logoutAction } from "../../../actions/auth-actions";
import { pushUrlPathAction, updateReservationAction } from "../../../actions/reservation-actions";
import { backToReservationAction, changeVenuePackageAction, changeVenuePackageWithReservationAction, parseHash } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import { selectAuthenticated } from "../../../reducers/auth-reducer";
import { selectIsUpdateReservation, selectReservation } from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue, selectVenues } from "../../../reducers/venues";
import { State, VenueName } from "../../../store/types";
import MakeReservationDrawer from "../MakeReservationDrawer/MakeReservationDrawer";
import { useSelectWithIconStyles } from "../SelectWithIcon/SelectWithIcon2";
import InfoIcon from "./InfoIcon";
import "./header.scss";
interface HeaderProps {
    showReservation?: boolean;
    party?: boolean;
    cancellation?: boolean;
    repay?: boolean;
    handleBackButton?: () => void;
}

registerIcons({
    icons: {
        "loginIcon-svg": <LoginIcon />,
    },
});

const RIGHT_ICON_SIZE = 40;

const backButton: IIconProps = { iconName: "Back" };

const MobileRightIcon = ({
    name,
    handler,
    headerForegroundColor,
    headerBackgroundColor,
}: {
    name: string;
    handler: () => void;
    headerForegroundColor?: string;
    headerBackgroundColor?: string;
}) => (
    <IconButton
        iconProps={{
            iconName: "loginIcon-svg",
            styles: {
                root: {
                    height: RIGHT_ICON_SIZE,
                    width: RIGHT_ICON_SIZE,
                    fill: headerForegroundColor,
                },
            },
        }}
        styles={{
            root: {
                height: RIGHT_ICON_SIZE,
                width: RIGHT_ICON_SIZE,
                marginRight: "20px",
                paddingRight: 0,
            },
            rootHovered: { background: headerBackgroundColor },
            rootPressed: { background: headerBackgroundColor },
        }}
        name={name}
        onClick={handler}
        className="focusableHeaderButton"
    ></IconButton>
);

const mapDispatchToProps = {
    pushUrlPath: pushUrlPathAction,
    backToReservation: backToReservationAction,
    changeVenuePackageWithReservation: changeVenuePackageWithReservationAction,
    changeVenuePackage: changeVenuePackageAction,
    updateReservation: updateReservationAction,
    logout: logoutAction

};

const mapStateToProps = (state: State) => ({
    reservation: selectReservation(state),
    venue: selectVenue(state),
    authenticated: selectAuthenticated(state),
    pageLocation: getLocation(state),
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    venues: selectVenues(state),
    searchParams: getSearch(state),
    hashParams: getHash(state),
    isUpdateReservation: selectIsUpdateReservation(state)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector> & HeaderProps;

const Header2 = ({
    showReservation,
    party,
    cancellation,
    repay,
    authenticated,
    pushUrlPath,
    backToReservation,
    reservation,
    venue,
    pageLocation,
    uiConfig,
    handleBackButton,
    currentPackage,
    venues,
    searchParams,
    isUpdateReservation,
    changeVenuePackageWithReservation,
    changeVenuePackage,
    updateReservation,
    hashParams,
    logout
}: Props) => {
    const { isMobile } = useViewport();
    const [isMakeReservationModalOpen, setIsMakeReservationModalOpen] = React.useState(false);
    const [venueMenuAnchor, setVenueMenuAnchor] = React.useState(null);

    const handleChangeVenue = (venue: VenueName) => {
        selectVenueOption(venue.id);
        handleCloseVenueMenu();
    }
    const handleCloseVenueMenu = () => {
        setVenueMenuAnchor(null);
    }
    const handleOpenVenueMenu = (event: any) => {
        setVenueMenuAnchor(event.currentTarget);
    }
    const handleMakeReservationModalClose = () => {
        setIsMakeReservationModalOpen(false);
    }
    const handleMakeReservationModalOpen = () => {
        setIsMakeReservationModalOpen(true);
    }
    const handleLogin = () => pushUrlPath("/reservation-info/account")
    const handleAccount = () => pushUrlPath("/account")

    const { date, duration, slots, guests, lanes, timeSlotShifting } = reservation;
    let { packagesTag } = parseHash(hashParams);

    const selectVenueOption = (newVenueId?: number | string) => {
        const newVenue = venues.find((venue) => venue.id == newVenueId);
        if (newVenueId && newVenue) {
            const recountedGuests = newVenue.maxGuests >= guests ? guests : 1;
            if (!!packagesTag) {
                replace({ search: searchParams });
            }
            if (isUpdateReservation) {
                updateReservation({ slots: undefined, packageId: undefined });
                changeVenuePackageWithReservation({
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    updatePackage: undefined,
                });
            } else {
                changeVenuePackage({
                    updatePackage: undefined,
                    venueId: newVenueId.toString(),
                    date,
                    guests: recountedGuests,
                    changeVenueWithoutPackage: true,
                });
            }
        }
    };

    const homeLogo = uiConfig?.homeLogo;
    const headerBackgroundColor = uiConfig?.headerBackgroundColor || '#0D245F';
    const headerForegroundColor = uiConfig?.headerForegroundColor || "#fff";
    const homeLink = uiConfig?.homeLink;
    const companyName = uiConfig?.companyName;
    const actionText = uiConfig?.actionText;
    const hideDuration = uiConfig?.hideDuration;
    const hideCreateAccount = uiConfig?.hideCreateAccount;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const classes = useSelectWithIconStyles()

    const contentStyles = mergeStyleSets({
        header: {
            background: headerBackgroundColor,
            color: headerForegroundColor,
        },
        element: {
            color: headerForegroundColor,
        },
    });

    const backButtonStiles: IButtonStyles = {
        root: {
            color: headerForegroundColor,
            border: `1px solid ${headerForegroundColor}`,
            borderRadius: "50%",
            marginLeft: "20px",
        },
    };

    const buttonStyle = (
        isMobile: boolean,
        isSmallWidthScreen: boolean
    ): IButtonStyles => ({
        root: {
            background: headerBackgroundColor,
            color: headerForegroundColor,
            padding: 0,
            border: 0,
            height: "100%",
            minWidth: "0",
            width: "auto",
            marginRight: "10px",
            fontSize: "inherit",
            textTransform: "uppercase",
        },
        rootHovered: {
            background: "transparent",
            color: headerForegroundColor,
        },
        rootPressed: {
            background: "transparent",
            color: headerForegroundColor,
        },
        flexContainer: {
            justifyContent: "flex-end",
        },
        textContainer: {
            flexGrow: 0,
            maxWidth: isMobile && isSmallWidthScreen ? "min-content" : "max-content",
        },
        label: {
            border: "1px solid transparent",
        },
        labelHovered: {
            borderBottom: `1px solid ${headerForegroundColor}`,
        },
    });

    const dateFormat =
        uiConfig?.dateFormat === DateFormat.MMDDYYYY ? "ddd, MM/D" : "ddd, D/MM";



    const totalPrice = getReservationTotalPrice(reservation);
    const parsedTotalPrice = formatPrice(totalPrice, reservation.currency, true);
    const title1 =
        showReservation && venue && date
            ? `${venue.name}: ${formatReservationDate(
                reservation,
                dateFormat
            )} @ ${slotToTime(
                slots,
                venue.timeSlotDuration,
                twelveHourClockFormat,
                timeSlotShifting
            )}`
            : null;
    const title2 =
        showReservation && venue && date
            ? currentPackage
                ? `${currentPackage.name} = ${parsedTotalPrice}`
                : `${lanes} ${actionText}${reservation.lanes < 2 ? "" : "s"} ${hideDuration
                    ? ""
                    : `, ${formatDuration(duration, venue.timeSlotDuration)}`
                } = ${parsedTotalPrice}`
            : null;
    const onRightButtonClick = () => {
        if (reservation.price) {
            pushUrlPath("/reservation-info/reservation-confirm", {
                venue: reservation.venueName,
            });
        } else {
            backToReservation();
        }
    };

    const venuesForChange = venues?.filter(venueInList => !venueInList.isComingSoon);
    const isShowingSelect = window && window.location && window.location.href.includes('package');
    const isNoVenues = (venuesForChange && (venuesForChange.length === 1 || venuesForChange.length === 0)) || !venuesForChange

    const notShowingSelect = (!isShowingSelect || isNoVenues)
    if (isMobile) {
        return (
            <>
                <Box className={`header ${contentStyles.header}`} bgcolor={headerBackgroundColor || '#0D245F'} justifyContent={'space-between'}>

                    <Box display={'flex'} alignItems={'center'} style={{ gap: '16px' }} ml={'24px'}>

                        {handleBackButton && (
                            <Button onClick={handleBackButton} className="header-link-v2" style={{ backgroundColor: headerForegroundColor }}>
                                <ChevronLeft style={{ color: headerBackgroundColor, fontSize: '40px' }} /></Button>
                        )}
                        <Link className={`logo-link v2 ${notShowingSelect ? 'not-showing-select' : ''}`} href={homeLink}>
                            <img
                                className="makeReservation-top-image"
                                src={homeLogo}
                                alt={companyName}
                                style={{
                                    maxHeight: notShowingSelect ? '100%' : '43px',
                                    maxWidth: notShowingSelect ? '100%' : '94px'
                                }}
                            />
                        </Link>
                    </Box>
                    {(isShowingSelect) ? <>
                        {venuesForChange && venuesForChange.length > 1 ?
                            <>
                                <Box display={'flex'} alignItems={'center'} style={{ gap: '4px' }} onClick={handleOpenVenueMenu}>
                                    <Typography className="body-huge-venue truncate" style={{ color: headerForegroundColor, maxWidth: '120px' }}>
                                        {venue?.name}
                                    </Typography>
                                    <ChevronLeft style={{ color: headerForegroundColor, transform: 'rotate(-90deg)' }} />
                                </Box>

                                <Menu
                                    anchorEl={venueMenuAnchor}
                                    keepMounted
                                    open={Boolean(venueMenuAnchor)}
                                    onClose={handleCloseVenueMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                    style={{ marginTop: '48px' }}
                                    classes={{
                                        paper: classes.menu,
                                        list: classes.menuList
                                    }}
                                >
                                    {venuesForChange.map((venueName) => (
                                        <MenuItem
                                            key={venueName.id}
                                            onClick={() => handleChangeVenue(venueName)}
                                            className={classes.menuItem}
                                        >
                                            {venue?.name === venueName.name && <Check style={{ position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }} />}
                                            {venueName.name}
                                        </MenuItem>
                                    ))}
                                </Menu>

                            </> : null}
                        <Button className="header-link-v2 mr" onClick={handleMakeReservationModalOpen} style={{ backgroundColor: headerForegroundColor }}>
                            <InfoIcon color={headerBackgroundColor} />
                        </Button>
                    </> : null}
                </Box>
                <MakeReservationDrawer open={isMakeReservationModalOpen} onClose={handleMakeReservationModalClose} venue={venue} isLoggedIn={authenticated} onLogout={logout} onLogin={handleLogin} onAccount={handleAccount} />
            </>
        )
    }
    const isSmallWidthScreen = document.documentElement.clientWidth < 500;
    if (party) {
        return (
            <div className={`header ${contentStyles.header}`}>
                <div className="party-header">
                    <a className="logo-link" href={homeLink}>
                        <img
                            className="makeReservation-top-image"
                            src={homeLogo}
                            alt={companyName}
                        ></img>
                    </a>
                </div>
                {handleBackButton && (
                    <div className="makeReservation-top-backButton">
                        <IconButton
                            className="focusableRoundButton"
                            iconProps={backButton}
                            styles={backButtonStiles}
                            onClick={handleBackButton}
                            name="back"
                        />
                    </div>
                )}
            </div>
        );
    }
    if (cancellation) {
        return (
            <div className={`header ${contentStyles.header}`}>
                <div className="cancellation-header">
                    <a className="logo-link" href={homeLink}>
                        <img
                            className="makeReservation-top-image"
                            src={homeLogo}
                            alt={companyName}
                        ></img>
                    </a>
                </div>
            </div>
        );
    }
    if (repay) {
        return (
            <div className={`header ${contentStyles.header}`}>
                <a className="logo-link" href={homeLink}>
                    <img
                        className="makeReservation-top-image"
                        src={homeLogo}
                        alt={companyName}
                    ></img>
                </a>
            </div>
        );
    }
    return (
        <Box className={`header`} justifyContent={'space-between'} bgcolor={headerBackgroundColor} paddingX={'22px'}>

            <Button onClick={handleBackButton} className="gallery-btn">
                <ArrowBack style={{ color: headerForegroundColor }} />
            </Button>
            {showReservation ? (
                <Box className={`reservation-info ${contentStyles.element}`}>
                    <Box className="title">{title1}</Box>
                    <Box className="title">{title2}</Box>
                </Box>
            ) : (
                <a className="logo-link logo-link-v2-desktop" href={homeLink}>
                    <img
                        className="makeReservation-top-image"
                        src={homeLogo}
                        alt={companyName}
                    ></img>
                </a>
            )}
            {!hideCreateAccount ? (
                <Box className="makeReservation-top-loginButton v2">
                    {authenticated ? (
                        pageLocation.pathname === "/account" ? (
                            <DefaultButton
                                className="focusableHeaderButton"
                                styles={buttonStyle(isMobile, isSmallWidthScreen)}
                                onClick={onRightButtonClick}
                                name="reservation"
                            >
                                {reservation.price ? "RESERVATION" : "MAKE A RESERVATION"}
                            </DefaultButton>
                        ) : isMobile ? (
                            <MobileRightIcon
                                name="account"
                                handler={handleAccount}
                                headerForegroundColor={headerForegroundColor}
                                headerBackgroundColor={headerBackgroundColor}
                            />
                        ) : (
                            <Button
                                className="gallery-btn"
                                onClick={handleAccount}
                                name="account"
                            >
                                <Person style={{ color: headerForegroundColor }} />
                            </Button>
                        )
                    ) : isMobile ? (
                        <MobileRightIcon
                            name="login"
                            handler={handleLogin}
                            headerForegroundColor={headerForegroundColor}
                            headerBackgroundColor={headerBackgroundColor}
                        />
                    ) : (
                        <DefaultButton
                            className="focusableHeaderButton"
                            styles={buttonStyle(isMobile, isSmallWidthScreen)}
                            onClick={handleLogin}
                            name="login"
                        >
                            LOGIN
                        </DefaultButton>
                    )}
                </Box>
            ) : <Box />}
        </Box>
    );
};

export default connector(Header2);
