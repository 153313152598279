import { Box, Button } from "@material-ui/core";
import { getSearch, goBack } from "connected-react-router";
import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { ConnectedProps, connect } from "react-redux";
import AccountBoxIcon from "../../../assets/account/account_box.svgr";
import AddBoxIcon from "../../../assets/account/add_box.svgr";
import VerifiedIcon from "../../../assets/account/verified.svgr";
import CreditCardIcon from "../../../assets/account/credit_card.svgr";
import LocalActivityIcon from "../../../assets/account/local_activity.svgr";
import LogoutIcon from "../../../assets/account/logout.svgr";
import {
  authWithReservationTokenAction,
  changePasswordAction,
  cleanAuthErrorAction,
  logoutAction,
  updateProfileAction,
} from "../../actions/auth-actions";
import {
  backFromAccountAction,
  getReservationsAction,
  hideCancellationAction,
  pushUrlPathAction,
} from "../../actions/reservation-actions";
import { useViewport } from "../../hooks/responsive";
import {
  selectAuthError,
  selectAuthenticated,
  selectClient,
  selectIsAuthRequestInProgress,
  selectIsPasswordChanged,
} from "../../reducers/auth-reducer";
import {
  selectIsRequestInProgress,
  selectReservation,
  selectReservations,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { PMethod, State } from "../../store/types";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import { ChangePassword2 } from "./ChangePassword/ChangePassword2";
import { EditProfile2 } from "./EditProfile/EditProfile2";
import AccountReservations2 from "./Reservations/AccountReservations2";
import "./account.scss";
import Header2 from "../common/Header/Header2";
import Footer2 from "../common/Footer/Footer2";
import SwipeableBottomDrawer from "../common/SwipeableDrawer/SwipeableBottomDrawer";
import { selectVenue } from "../../reducers/venues";
import CloseIcon from "../../../assets/close.svgr";
import AccountMenuItem from "./AccountMenuItem";
import CalendarIcon from "../../../assets/calendar.svgr";
import AccountIcon from "../../../assets/account-circle.svgr";
import CirclePlusIcon from "../../../assets/circle-plus.svgr";
import { getBillingSessionAction, getBillingSessionByClientAction, unSubscribeAction } from "../../actions/membership-actions";
import AccountMemberships from "./Memberships/AccountMemberships";
import { selectError } from "../../reducers/membership";
import NoAvailiableGuests from "../PackageCard/NoAvailiableGuests";

enum AccountState {
  RESERVATIONS,
  PROFILE,
  CHANGE_PASSWORD,
  MEMBERSHIPS
}

const mapStateToProps = (state: State) => ({
  authenticated: selectAuthenticated(state),
  client: selectClient(state),
  reservation: selectReservation(state),
  reservations: selectReservations(state),
  isResRequestInProgress: selectIsRequestInProgress(state),
  isAuthRequestInProgress: selectIsAuthRequestInProgress(state),
  isPasswordChanged: selectIsPasswordChanged(state),
  authError: selectAuthError(state),
  uiConfig: selectUIConfig(state),
  searchParams: getSearch(state),
  activeVenue: selectVenue(state),
  error: selectError(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
  authWithReservationToken: authWithReservationTokenAction,
  getReservations: getReservationsAction,
  changePassword: changePasswordAction,
  updateProfile: updateProfileAction,
  cleanAuthError: cleanAuthErrorAction,
  hideCancellation: hideCancellationAction,
  goBack,
  backFromAccount: backFromAccountAction,
  pushUrlPath: pushUrlPathAction,
  unSubscribe: unSubscribeAction,
  getBiliingSession: getBillingSessionByClientAction,

};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Prop = ConnectedProps<typeof connector>;

const Account2 = ({
  logout,
  getReservations,
  changePassword,
  updateProfile,
  authenticated,
  pushUrlPath,
  goBack,
  reservation,
  reservations,
  client,
  isResRequestInProgress,
  isAuthRequestInProgress,
  isPasswordChanged,
  authError,
  uiConfig,
  backFromAccount,
  cleanAuthError,
  hideCancellation,
  searchParams,
  authWithReservationToken,
  activeVenue,
  unSubscribe,
  getBiliingSession,
  error
}: Prop) => {
  const { isMobile, isTabletScreen } = useViewport();
  const [isOpenModalBottom, setIsOpenModalBottom] = useState(false);
  const handleOpenBottomModal = () => {
    setIsOpenModalBottom(true);
  };
  const handleCloseBottomModal = () => {
    setIsOpenModalBottom(false);
  };
  useEffect(() => {
    const { token, id } = parseUrlQuery(searchParams);
    console.log(authenticated, "authenticated", token, "token");
    if (!authenticated && !token) {
      backFromAccount();
    }
    if (!authenticated && token) {
      authWithReservationToken(id, token);
    }
    hideCancellation();
  }, [authenticated, hideCancellation]);
  useEffect(() => {
    hideCancellation();
    if (authenticated && page === AccountState.RESERVATIONS) {
      getReservations();
    }
  }, [getReservations, hideCancellation, authenticated]);
  const [page, setPage] = useState<AccountState>(AccountState.RESERVATIONS);
  const onLogout = () => {
    logout();
    backFromAccount();
  };
  const onReservation = () => {
    if (reservation.price) {
      pushUrlPath("/reservation-info/reservation-confirm", {
        venue: reservation.venueName
      });
    } else {
      backFromAccount();
    }
  };

  const onMemmberhips = () => {
    setPage(AccountState.MEMBERSHIPS);
  }
  const onBack = () => {
    // if (lastLocation?.pathname.includes("/reservation-confirm")) {
    //   backToReservation();
    // } else {
    goBack();
    // }
  };

  const handleOpenMyReservations = () => {
    setPage(AccountState.RESERVATIONS);
    handleCloseBottomModal();
  };
  const handleOpenAccountDetails = () => {
    setPage(AccountState.PROFILE);
    handleCloseBottomModal();
  };
  const handleMakeReservation = () => {
    pushUrlPath("/reservation-info");
    handleCloseBottomModal();
  };
  const handleOpenMemberships = () => {
    setPage(AccountState.MEMBERSHIPS);
    handleCloseBottomModal();
  }
  const handleLogout = () => {
    onLogout();
    handleCloseBottomModal();
  };

  const handlePaymentMethods = () => {
    getBiliingSession(client?.id || '');
  };

  if (isMobile) {
    return (
      <>
        <Box>
          <Header2 handleBackButton={onBack} />
          {(() => {
            switch (page) {
              case AccountState.RESERVATIONS:
                return (
                  <AccountMenuItem
                    m={"32px 24px"}
                    icon={<CalendarIcon />}
                    withArrow
                    onClick={handleOpenBottomModal}
                    title="My Reservations"
                  />
                );
              case AccountState.PROFILE:
                return (
                  <AccountMenuItem
                    m={"32px 24px"}
                    icon={<AccountIcon />}
                    withArrow
                    onClick={handleOpenBottomModal}
                    title="Account Details"
                  />
                );

              default:
                break;
            }
          })()}

          {((isResRequestInProgress &&
            (!reservations || (reservations && reservations.length === 0))) ||
            isAuthRequestInProgress) && (
              <div
                className="spinner"
                style={{
                  height: isMobile ? "50vh" : "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner size={SpinnerSize.large} />
              </div>
            )}

          {!(
            (isResRequestInProgress &&
              (!reservations || (reservations && reservations.length === 0))) ||
            isAuthRequestInProgress
          ) && (
              <Box minHeight={"100vh"} bgcolor={"#F9F9FC"}>
                {(() => {
                  switch (page) {
                    case AccountState.RESERVATIONS:
                      return (
                        <div style={{ padding: "32px 24px" }}>
                          <AccountReservations2 reservations={reservations} />
                        </div>
                      );

                    case AccountState.PROFILE:
                      return (
                        <div style={{ padding: "32px 0px" }}>
                          <EditProfile2
                            updateProfile={updateProfile}
                            client={client}
                            isInProgress={isAuthRequestInProgress}
                            error={authError}
                            cleanError={cleanAuthError}
                            uiConfig={uiConfig}
                            unSubscribe={unSubscribe}
                          >
                            <ChangePassword2
                              changePassword={changePassword}
                              isInProgress={isAuthRequestInProgress}
                              isPasswordChanged={isPasswordChanged}
                              error={authError}
                              cleanError={cleanAuthError}
                              uiConfig={uiConfig}
                            />
                          </EditProfile2>
                        </div>
                      );
                    case AccountState.MEMBERSHIPS:
                      return (
                        <div style={{ padding: "32px 24px" }}>
                          <AccountMemberships subscriptions={client?.subscriptions || []} />
                        </div>
                      );
                    default:
                      break;
                  }
                })()}
              </Box>
            )}
          {/* <Footer2 /> */}
        </Box>
        <SwipeableBottomDrawer
          isMakeReservation
          open={isOpenModalBottom}
          onClose={handleCloseBottomModal}
          activeVenue={activeVenue}
        >
          <Box p={"32px 24px"}>
            <Button className="gallery-btn" onClick={handleCloseBottomModal}>
              <CloseIcon />
            </Button>
            <Box
              mt={"24px"}
              display={"flex"}
              flexDirection={"column"}
              style={{ gap: "8px" }}
            >
              <AccountMenuItem
                icon={<CalendarIcon />}
                onClick={handleOpenMyReservations}
                title="My Reservations"
                disabled={page !== AccountState.RESERVATIONS}
              />
              <AccountMenuItem
                icon={<AccountIcon />}
                onClick={handleOpenAccountDetails}
                title="Account Details"
                disabled={page !== AccountState.PROFILE}
              />
              <AccountMenuItem
                icon={<AccountIcon />}
                onClick={handlePaymentMethods}
                title="Payment Details"
                disabled={activeVenue?.paymentType !== PMethod.stripeV2}
              />
              <AccountMenuItem
                icon={<CirclePlusIcon />}
                onClick={handleMakeReservation}
                title="Make a New reservation"
                noBgcolor
              />
              {uiConfig?.hasMemberships ? <AccountMenuItem
                icon={<CirclePlusIcon />}
                onClick={handleOpenMemberships}
                title="Memberships"
                noBgcolor
                disabled={page !== AccountState.MEMBERSHIPS}
              /> : null}
              {/* <AccountMenuItem icon={<LockIcon />} onClick={handleChangePassword} title='Change Password' disabled={page !== AccountState.CHANGE_PASSWORD} /> */}
              <AccountMenuItem
                icon={<LogoutIcon />}
                onClick={handleLogout}
                title="Logout"
                noBgcolor
              />
            </Box>
          </Box>
        </SwipeableBottomDrawer>
      </>
    );
  }
  let accountMenuStyles = "account-menu-v2";
  if (isMobile) {
    accountMenuStyles += " mobile";
  }
  if (!client) {
    accountMenuStyles += " guestGrid";
  }
  if (isTabletScreen) {
    accountMenuStyles += " is-tablet-v2";
  }

  let accountNavigationStyle = "accountMenu account-navigation-v2";
  if (isMobile) {
    accountNavigationStyle += " mobile";
  }
  if (isTabletScreen) {
    accountNavigationStyle += " is-tablet-v2";
  }


  return (
    <>

      <Box className="account-v2">
        <Header2 handleBackButton={onBack} />

        <Box className={accountMenuStyles}>
          <Box className={accountNavigationStyle}>
            <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                } ${page === AccountState.RESERVATIONS ? "selected" : ""}`}
              onClick={() => setPage(AccountState.RESERVATIONS)}
              name="reservations"
            >
              <LocalActivityIcon />
              My Reservations
            </Button>
            <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                } ${page === AccountState.PROFILE ? "selected" : ""}`}
              onClick={() => setPage(AccountState.PROFILE)}
            >
              <AccountBoxIcon />
              Profile
            </Button>
            <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                }`}
              onClick={handlePaymentMethods}
              disabled={activeVenue?.paymentType !== PMethod.stripeV2}
            >
              <CreditCardIcon />
              Payment Methods
            </Button>
            <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                }`}
              onClick={onReservation}
              tabIndex={0}
              onKeyPress={onReservation}
            >
              <AddBoxIcon />
              {reservation.price
                ? "Continue with Reservation"
                : "Make a New Reservation"}
            </Button>

            {uiConfig?.hasMemberships ? <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                } ${page === AccountState.MEMBERSHIPS ? "selected" : ""}`}
              onClick={onMemmberhips}
              name="memberships"
            >
              <VerifiedIcon />
              Memberships
            </Button> : null}
            <Button
              className={`btn-account-2 ${isTabletScreen ? "is-tablet-v2" : ""
                } `}
              onClick={onLogout}
              name="logout"
            >
              <LogoutIcon />
              Logout
            </Button>
          </Box>

          {((isResRequestInProgress &&
            (!reservations || (reservations && reservations.length === 0))) ||
            isAuthRequestInProgress) && (
              <div
                className="spinner"
                style={{
                  height: "100vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner size={SpinnerSize.large} />
              </div>
            )}

          {!(
            (isResRequestInProgress &&
              (!reservations || (reservations && reservations.length === 0))) ||
            isAuthRequestInProgress
          ) && (
              <Box p={isTabletScreen ? "36px" : "48px 104px"} minHeight={"100vh"}>
                {(error && page !== AccountState.MEMBERSHIPS) && <NoAvailiableGuests noSpace text={error} mb={'12px'} />}

                {(() => {
                  switch (page) {
                    case AccountState.RESERVATIONS:
                      return <AccountReservations2 reservations={reservations} />;

                    case AccountState.PROFILE || AccountState.CHANGE_PASSWORD:
                      return (
                        <EditProfile2
                          updateProfile={updateProfile}
                          client={client}
                          isInProgress={isAuthRequestInProgress}
                          error={authError}
                          cleanError={cleanAuthError}
                          uiConfig={uiConfig}
                          unSubscribe={unSubscribe}
                        >
                          <ChangePassword2
                            changePassword={changePassword}
                            isInProgress={isAuthRequestInProgress}
                            isPasswordChanged={isPasswordChanged}
                            error={authError}
                            cleanError={cleanAuthError}
                            uiConfig={uiConfig}
                          />
                        </EditProfile2>
                      );
                    case AccountState.MEMBERSHIPS:
                      return <AccountMemberships subscriptions={client?.subscriptions || []} />
                    default:
                      break;
                  }
                })()}
              </Box>
            )}
        </Box>
      </Box>
      <Footer2 />
    </>
  );
};

export default connector(Account2);
