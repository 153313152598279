import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import React from 'react';
import HTMLRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { rem } from '../../../../../common/utils/formats';

const checkboxStyle = {
    root: {
        margin: "0 0 30px",
    },
    checkbox: {
        width: rem(20),
        height: rem(20),
    },
    text: {
        fontSize: rem(13),
        lineHeight: "1.5",
    },
    checkmark: {
        fontSize: rem(13),
    },
};

interface Props {
    venueInfo?: string;
    selected: boolean;
    onChange: (_: any, isChecked?: boolean) => void;
}

const VenuePolicies = ({ venueInfo, selected, onChange }: Props) => {
    return (
        <Box pb={"100px"}>
            <Box className="venue-policy">
                <Box className="heading">VENUE POLICIES</Box>
                <HTMLRenderer
                    html={venueInfo}
                    className="venue-policy-value"
                />
            </Box>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={selected}
                        onChange={onChange}
                        name="checked"
                        color="default"
                        style={{
                            color: "#2E3A48",
                        }}
                    />
                }
                label={<Typography className='body-small'>I have read and agree to the venue policies.</Typography>}
                style={{ ...checkboxStyle, marginBottom: rem(16) }}

            />
        </Box>
    )
}

export default VenuePolicies