import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import LockSmIcon from '../../../assets/lock_sm.svgr'
import { useViewport } from '../../hooks/responsive';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: '#EBECF0',
        color: '#000000',
        borderRadius: '4px',
        textTransform: 'none',
        width: '180px',
        height: '48px',
        transition: 'all 0.15s',
        padding: 0,
        '&:hover': {
            opacity: 0.8,
        },
    },
    buttonTablet: {
        width: '40px',
        height: '40px',
        minWidth: '40px',
    },
    buttonMobile: {
        width: '48px',
        height: '48px',
        minWidth: '48px',
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '24px'
    }
}))

interface Props {
    onClick?: () => void;
}

const MemberSignInButton = ({ onClick }: Props) => {
    const classes = useStyles();
    const { isMobile, isTabletScreen } = useViewport();
    const isDesktop = !isMobile && !isTabletScreen;
    const buttonClassName = isMobile ? classes.buttonMobile : isTabletScreen ? classes.buttonTablet : '';
    return (
        <Button className={`${classes.button} ${buttonClassName}`} classes={{ label: classes.label }} onClick={onClick}
        >
            {isDesktop ? 'Members Sign In' : null}
            <LockSmIcon />
        </Button>
    )
}

export default MemberSignInButton