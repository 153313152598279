export const delay = (timer: number) => new Promise(resolve => setTimeout(resolve, timer));

type WithRetry = {
    toTry: CallableFunction;
    retries: number;
    interval: number;
}
export const withRetry = async ({ toTry, retries = 3, interval = 500 }: WithRetry) => {
    if (retries < 1) throw new Error(`Bad argument: 'times' must be greater than 0, but ${retries} was received.`);
    let error = ''
    for (let i = 0; i < retries; i++) {
        try {
            const result = await toTry();
            return result;
        } catch (e) {
            error = e;
        }
        await delay(interval)
    }
    throw error;
}

const resolveAfter = (ms: number) => new Promise(ok => setTimeout(ok, ms));
export function rateLimit(fn: CallableFunction, msPerOp: number) {
    let wait: Promise<any> = Promise.resolve();
    return (...a: any[]) => {
        // We use the queue tail in wait to start both the
        // next operation and the next delay
        const res = wait.then(() => fn(...a));
        wait = wait.then(() => resolveAfter(msPerOp));
        return res;
    };
}

export const jsonBigintReplacer = (key: string, value: unknown) => typeof value === 'bigint' ? value.toString() : value

export const getArrayFromString = (str: string | undefined, separator = ','): string[] => {
    if (!str) return [];
    return str.split(separator);
};

export const arrayToObjectByProperty = <T extends object>(objectsArray: T[], propertyName: keyof T): Record<string, T> => {
    return objectsArray.reduce((result, obj) => {
        const key = obj[propertyName];
        if (typeof key !== 'string') return result;
        result[key.toString()] = obj;
        return result;
    }, {} as Record<string, T>);
};

export const getURLSubdomain = (url: string): string => {
    try {
        const parsedUrl = new URL(url);
        const hostParts = parsedUrl.hostname.split('.');
        if (hostParts.length < 3) return '';
        return hostParts[0];
    } catch (error) {
        // ignore
        return ''
    }
};



export const extractValuesWithOn = (customFieldsInfo: string): string => {
    const emails: string[] = [];

    if (!customFieldsInfo) {
        return '';
    }

    const pairs = customFieldsInfo.split(';');
    for (const pair of pairs) {
        if (!pair) continue;

        let [key, value] = pair.split(/:(.*)/s);

        if (value && value.endsWith(':on')) {
            // Remove the ':on' suffix
            value = value.slice(0, -3).trim();
            // Split the value by commas and trim spaces
            const items = value.split(',').map(item => item.trim());
            // Collect the items into the emails array
            emails.push(...items);
        }
    }

    if (emails.length === 0) {
        return '';
    }
    // Remove repeated emails
    const uniqueEmails = Array.from(new Set(emails));

    // Join all emails into one string, separated by commas
    return uniqueEmails.join(',');
}