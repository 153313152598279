import React, { useState, useEffect } from "react";
import { goBack } from "connected-react-router";
import { useLastLocation } from "react-router-last-location";
import { getSearch } from "connected-react-router";
import Header from "../common/Header/Header";
import Footer from "../common/Footer/Footer";
import {
  DefaultButton,
  IButtonStyles,
  Spinner,
  SpinnerSize,
} from "office-ui-fabric-react";
import {
  logoutAction,
  changePasswordAction,
  updateProfileAction,
  cleanAuthErrorAction,
  authWithReservationTokenAction,
} from "../../actions/auth-actions";
import {
  getReservationsAction,
  cancelReservationAction,
  hideCancellationAction,
  showReservationCancellationAction,
  backFromAccountAction,
  pushUrlPathAction,
  editReservationAction,
  hideModificationAction,
  clearReservationModificationAction,
  showReservationModificationAction,
} from "../../actions/reservation-actions";
import { backToReservationAction } from "../../actions/venue-actions";
import { connect, ConnectedProps } from "react-redux";
import { State, Reservation, PaymentType, FeeType } from "../../store/types";
import {
  selectAuthenticated,
  selectClient,
  selectIsAuthRequestInProgress,
  selectAuthError,
  selectIsPasswordChanged,
} from "../../reducers/auth-reducer";
import {
  selectReservation,
  selectReservations,
  selectIsRequestInProgress,
  selectCancellationFee,
  selectShowCancellation,
  selectCancellationFeeType,
  selectFeeForModification,
  selectShowModification,
  selectModificationFeeType,
} from "../../reducers/reservation";
import { selectUIConfig } from "../../reducers/ui-reducer";
import { EditProfile } from "./EditProfile/EditProfile";
import { ChangePassword } from "./ChangePassword/ChangePassword";
import dayjs from "dayjs";
import { useViewport } from "../../hooks/responsive";
import { FULL_DATE_FORMAT, DATE_FORMAT } from "../../../../common/constants/timedate";
import { slotToTime, formatDuration, formatReservationDate, formatPrice, getTotalPrice } from "../../../../common/utils/formats";
import { parseUrlQuery } from "../../utils/urlSearchQuery";
import "./account.scss";
import CancelReservationConfirmationModal from "./CancelReservationConfirmationModal/CancelReservationConfirmationModal";
import ModifyReservationConfirmationModal from "./ModifyReservationConfirmationModal/ModifyReservationConfirmationModal";

enum AccountState {
  RESERVATIONS,
  PROFILE,
  CHANGE_PASSWORD,
}

const accountButtonStyle = (
  isActive: boolean,
  isMobile: boolean,
  mainButtonColor?: string
): IButtonStyles => ({
  root: {
    color: isActive ? mainButtonColor : '#13171F',
    border: "none",
    height: isMobile ? "auto" : "100%",
    width: isMobile ? "100%" : "unset",
    fontSize: "medium",
    margin: 0,
    padding: "0",
    paddingLeft: isMobile ? "0" : "24px",
    background: (isActive && !isMobile) ? "#F4F7FE" : "transparent",
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100px',
  },
  label: {
    fontWeight: isActive ? "700" : "400",
  },
  rootHovered: {
    background: "#F4F7FE",
    color: isActive ? mainButtonColor : "black",
  },
  rootPressed: {
    background: "#F4F7FE",
    color: isActive ? mainButtonColor : "black",
  },
});

const renderReservationItem = (
  reservation: Reservation,
  showModal: (reservation: Reservation) => void,
  updateReservation: (reservation: Reservation) => void,
  isMobile: boolean,
  twelveHourClockFormat: boolean,
  actionText?: string,
  hideDuration?: boolean,
) => {
  const venueDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: reservation.timeZone })
  );
  const now = dayjs(venueDate).format(DATE_FORMAT);
  const currentTimeSlot = Math.floor(
    (venueDate.getHours() * 60 + venueDate.getMinutes()) /
    reservation.timeSlotDuration
  );
  const isCompleted =
    reservation.date === now
      ? reservation.slots <= currentTimeSlot
      : dayjs(reservation.date).isBefore(dayjs(venueDate));
  const isCancelled = reservation.status === "cancelled" || reservation.status === "cancelledNoRefund";
  const isUnpaid = reservation.status === "unpaid";
  const isUncaptured = reservation.status === "uncaptured";
  const isUnpaidStatus = isUnpaid && !isCompleted;
  const isCompletedStatus = isCompleted && !isCancelled;
  const isComingUpStatus = !isCompleted && !isCancelled;
  const isShowCancelReservationButton = reservation.allowCancellation && !isUncaptured;
  const isShowUpdateReservationButton = !isUncaptured &&
    reservation.paymentType !== PaymentType.sezzle &&
    reservation.paymentType !== PaymentType.goTab &&
    reservation.allowModification;
  const priceValue = getTotalPrice(reservation.price, reservation.tax, reservation.serviceFee, reservation.addonsPrice);
  const price = formatPrice(priceValue, reservation.currency, true);

  const renderTag = () => {
    let content = ''
    let className = ''
    if (isCompletedStatus) {
      className = 'is-completed'
      content = 'Completed'
    }

    if (isComingUpStatus) {
      className = 'is-upcoming'
      content = 'Upcoming'
    }

    if (isCancelled) {
      className = 'is-cancelled'
      content = 'Cancelled'
    }

    return <div className={`reservation-tag ${className}`}>{content}</div>
  }

  const guestsText = reservation.guests > 1 ? `${reservation.guests} guests` : `${reservation.guests} guest`;
  const lanesText = reservation.lanes > 1 ? `${reservation.lanes} ${actionText}s` : `${reservation.lanes} ${actionText}`;
  const durationText = formatDuration(reservation.duration, reservation.timeSlotDuration);

  return (
    <div className={`reservationInfo ${isMobile ? 'mobile' : ''}`} key={reservation.id}>
      <img src={reservation.venueImage} alt={reservation.venueName} className={`image ${isMobile ? 'mobile' : ''}`} />
      <div className='content'>
        <div>
          <h2 className={`reservation-number ${isMobile ? 'mobile' : ''}`}>{reservation.number}</h2>
          <p className='reservation-venue'>{reservation.venueName}</p>
          {reservation.packageName && <p className='reservation-detail'>{reservation.packageName}</p>}
          <p className='reservation-detail'>
            {`${formatReservationDate(reservation, FULL_DATE_FORMAT)} at ${slotToTime(reservation.slots, reservation.timeSlotDuration, twelveHourClockFormat, reservation.timeSlotShifting)}`}
          </p>
          <p className='reservation-detail'>
            {guestsText}, {lanesText}{hideDuration ? '' : `, ${durationText}`}
          </p>
          <p className='reservation-price'>
            {price}
          </p>
        </div>
        <div className='reservation-right-column'>
          {renderTag()}
          {
            isComingUpStatus && (
              <div className='reservation-buttons'>
                {isShowCancelReservationButton && (
                  <button
                    className="reservation-button focusable"
                    onClick={() => showModal(reservation)}
                    tabIndex={0}
                    onKeyPress={() => showModal(reservation)}
                  >
                    Cancel
                  </button>
                )}
                {isShowUpdateReservationButton && (
                  <button
                    className="reservation-button focusable"
                    onClick={() => updateReservation(reservation)}
                    tabIndex={0}
                    onKeyPress={() => updateReservation(reservation)}
                  >
                    Modify
                  </button>
                )}
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  authenticated: selectAuthenticated(state),
  client: selectClient(state),
  reservation: selectReservation(state),
  reservations: selectReservations(state),
  isResRequestInProgress: selectIsRequestInProgress(state),
  isAuthRequestInProgress: selectIsAuthRequestInProgress(state),
  isPasswordChanged: selectIsPasswordChanged(state),
  cancellationFee: selectCancellationFee(state),
  cancellationFeeType: selectCancellationFeeType(state),
  showCancellation: selectShowCancellation(state),
  feeForModification: selectFeeForModification(state),
  modificationFeeType: selectModificationFeeType(state),
  showModification: selectShowModification(state),
  authError: selectAuthError(state),
  uiConfig: selectUIConfig(state),
  searchParams: getSearch(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
  authWithReservationToken: authWithReservationTokenAction,
  getReservations: getReservationsAction,
  changePassword: changePasswordAction,
  updateProfile: updateProfileAction,
  cleanAuthError: cleanAuthErrorAction,
  backToReservation: backToReservationAction,
  cancelReservation: cancelReservationAction,
  hideCancellation: hideCancellationAction,
  showReservationCancellation: showReservationCancellationAction,
  goBack,
  backFromAccount: backFromAccountAction,
  pushUrlPath: pushUrlPathAction,
  editReservation: editReservationAction,
  hideModification: hideModificationAction,
  clearReservationModification: clearReservationModificationAction,
  showReservationModification: showReservationModificationAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type Prop = ConnectedProps<typeof connector>;

const Account = ({
  logout,
  getReservations,
  changePassword,
  updateProfile,
  authenticated,
  pushUrlPath,
  goBack,
  backToReservation,
  reservation,
  reservations,
  client,
  isResRequestInProgress,
  isAuthRequestInProgress,
  isPasswordChanged,
  authError,
  cancellationFee,
  cancellationFeeType,
  showCancellation,
  feeForModification,
  modificationFeeType,
  showModification,
  uiConfig,
  backFromAccount,
  cleanAuthError,
  cancelReservation,
  hideCancellation,
  hideModification,
  clearReservationModification,
  showReservationCancellation,
  showReservationModification,
  editReservation,
  searchParams,
  authWithReservationToken,
}: Prop) => {
  const { isMobile } = useViewport();
  const lastLocation = useLastLocation();
  useEffect(() => {
    const { token, id } = parseUrlQuery(searchParams);
    if (!authenticated && !token) {
      backFromAccount();
    }
    if (!authenticated && token) {
      authWithReservationToken(id, token)
    }
    hideCancellation();
  }, [authenticated, hideCancellation]);
  useEffect(() => {
    hideCancellation();
    if (authenticated) {
      getReservations();
    }
  }, [getReservations, hideCancellation, authenticated]);
  const [page, setPage] = useState<AccountState>(AccountState.RESERVATIONS);
  const [currentReservation, setCurrentReservation] = useState<Reservation>();
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  const onLogout = () => {
    logout();
    backFromAccount();
  };
  const onReservation = () => {
    if (reservation.price) {
      pushUrlPath("/reservation-info/reservation-confirm", {
        venue: reservation.venueName,
      });
    } else {
      backFromAccount();
    }
  };
  const onBack = () => {
    // if (lastLocation?.pathname.includes("/reservation-confirm")) {
    //   backToReservation();
    // } else {
    goBack();
    // }
  };
  const showCancellationModal = (reservation: Reservation) => {
    showReservationCancellation(reservation.id || "");
    setCurrentReservation(reservation);
  };
  const doCancelReservation = (reason: string) => {
    hideCancellation();
    if (currentReservation && currentReservation.id) {
      cancelReservation(currentReservation.id, reason);
    }
  };
  const showModificationModal = (reservation: Reservation) => {
    showReservationModification(reservation.id || "");
    setCurrentReservation(reservation);
  };
  const doModifyReservation = () => {
    hideModification();
    if (currentReservation && currentReservation.id) {
      editReservation(currentReservation);
    }
  };
  const onCloseModificationModal = () => {
    hideModification();
    clearReservationModification();
  }

  return (
    <div className="account-container main-container">
      <Header handleBackButton={onBack} />
      <div
        className={`account-inner-container main-content ${isMobile ? "mobile" : ""
          }`}
      >
        <div className={`title ${isMobile ? "mobile" : ""}`}>Account</div>
        <div className={`grid ${isMobile ? "mobile" : ""} ${!client ? "guestGrid" : ""}`}>
          {client && (
            <div className={`accountMenu ${isMobile ? "mobile" : ""}`}>
              <DefaultButton
                className="focusableButton"
                styles={accountButtonStyle(
                  page === AccountState.RESERVATIONS,
                  isMobile,
                  uiConfig?.mainButtonColor
                )}
                onClick={() => setPage(AccountState.RESERVATIONS)}
                name="reservations"
              >
                My Reservations
              </DefaultButton>
              <DefaultButton
                className="focusableButton"
                styles={accountButtonStyle(
                  page === AccountState.PROFILE,
                  isMobile,
                  uiConfig?.mainButtonColor
                )}
                onClick={() => setPage(AccountState.PROFILE)}
                name="editProfile"
              >
                Account Details
              </DefaultButton>
              <DefaultButton
                className="focusableButton"
                styles={accountButtonStyle(
                  false,
                  isMobile,
                  uiConfig?.mainButtonColor
                )}
                name="makeReservation"
                onClick={onReservation}
                tabIndex={0}
                onKeyPress={onReservation}
              >
                {reservation.price ? "Continue with Reservation" : "Make a New Reservation"}
              </DefaultButton>
              <DefaultButton
                className="focusableButton"
                styles={accountButtonStyle(
                  page === AccountState.CHANGE_PASSWORD,
                  isMobile,
                  uiConfig?.mainButtonColor
                )}
                onClick={() => setPage(AccountState.CHANGE_PASSWORD)}
                name="changePassword"
              >
                Change Password
              </DefaultButton>
              <DefaultButton
                className="focusableButton"
                styles={accountButtonStyle(
                  false,
                  isMobile,
                  uiConfig?.mainButtonColor
                )}
                onClick={onLogout}
                name="logout"
              >
                Logout
              </DefaultButton>
            </div>
          )}

          {(isResRequestInProgress || isAuthRequestInProgress) && (
            <div className="spinner">
              <Spinner size={SpinnerSize.large} />
            </div>
          )}

          {!isResRequestInProgress &&
            (() => {
              switch (page) {
                case AccountState.RESERVATIONS:
                  return (
                    <div className="reservations">
                      <div className="secondReservation">
                        {reservations.map((reservation) =>
                          renderReservationItem(
                            reservation,
                            showCancellationModal,
                            showModificationModal,
                            isMobile,
                            twelveHourClockFormat,
                            uiConfig?.actionText,
                            uiConfig?.hideDuration,
                          )
                        )}
                        {
                          showCancellation && (
                            <CancelReservationConfirmationModal
                              onOk={doCancelReservation}
                              currentReservation={currentReservation}
                              cancellationFee={cancellationFee}
                              cancellationFeeType={cancellationFeeType}
                              uiConfig={uiConfig}
                              onClose={hideCancellation}
                            />
                          )
                        }
                        {
                          showModification && (
                            <ModifyReservationConfirmationModal
                              onOk={doModifyReservation}
                              currentReservation={currentReservation}
                              feeForModification={feeForModification}
                              modificationFeeType={modificationFeeType}
                              uiConfig={uiConfig}
                              onClose={onCloseModificationModal}
                            />
                          )
                        }
                      </div>
                    </div>
                  );

                case AccountState.PROFILE:
                  return (
                    <EditProfile
                      updateProfile={updateProfile}
                      client={client}
                      isInProgress={isAuthRequestInProgress}
                      error={authError}
                      cleanError={cleanAuthError}
                      uiConfig={uiConfig}
                    />
                  );

                case AccountState.CHANGE_PASSWORD:
                  return (
                    <ChangePassword
                      changePassword={changePassword}
                      isInProgress={isAuthRequestInProgress}
                      isPasswordChanged={isPasswordChanged}
                      error={authError}
                      cleanError={cleanAuthError}
                      uiConfig={uiConfig}
                    />
                  );
                default:
                  break;
              }
            })()}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default connector(Account);
